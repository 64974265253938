/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useCallback, useEffect, useMemo} from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Settings page components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Header from "views/examples/account/settings/components/Header";
import BasicInfo from "views/examples/account/settings/components/BasicInfo";
import Specials from "views/examples/account/settings/components/Specials";
import Description from "views/examples/account/settings/components/Description";
import Photos from "views/examples/account/settings/components/Photos";
import Videos from "views/examples/account/settings/components/Videos";
import Certificates from "views/examples/account/settings/components/Certificates";
import Dictionaries from "views/examples/account/settings/components/Dictionaries";
import {
    Container,
} from "reactstrap";

import {useAuth} from "useAuth";
import _ from "lodash";
import {API} from "api";
import {FormattedMessage} from "react-intl";
const api = new API()

function Settings() {
    const {user, fetchUser} = useAuth();

    const [form, setForm] = React.useState({});
    const setFormField = useCallback((name, value) => {
        //console.log(name,value)
        setForm(f=>{
            //console.log('setForm',{...f, [name]: value?.target?value.target.value:value})
            if(name.indexOf(".")!==-1) {
                _.set(f, name, value?.target ? value.target.value : value)
                return {...f}
            } else {
                return ({...f, [name]: value?.target ? value.target.value : value})
            }
        })
        //console.log('after', name,form[name])
    }, [form])

    useEffect(()=>{
        console.log('fetchUser')
        setForm(user)
    }, [user])
    useEffect(()=>{
        if(form?.photos) {
            updateUser(['photos'], {photos: form?.photos})
        }
    }, [form?.photos?.length])

    useEffect(()=>{
        if(form?.certificates) {
            updateUser(['certificates'], {certificates: form?.certificates})
        }
    }, [form?.certificates?.length])


    const updateUser = useCallback(async (fields, data) => {
        console.log('updateUser',_.pick(form, fields))
        await api.post(`users/${form.uuid}`, data || _.pick(form, fields))
        await fetchUser()
    }, [form])
    function openWindowWithPost(url, data) {
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = url;
        form.style.display = "none";

        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }
    const showPreview = useCallback(async (fields, data) => {
        console.log('showPreview',form)
        localStorage.setItem(`coach-profile`, JSON.stringify(form))
        window.open(`/coach-profile-preview/${user.uuid}/anketa`)
    }, [form])



    return (
        <>
            <ExamplesNavbar/>
            <Container className={'mt-navbar mb-footer'}>
                {form.uuid && <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Header form={form} setFormField={setFormField} updateUser={updateUser}/>
                    </Grid>
                    <Grid item xs={12}>
                        <BasicInfo form={form} setFormField={setFormField} fetchUser={fetchUser} />
                    </Grid>
                    <Grid item xs={12}>
                        <Specials form={form} setFormField={setFormField} fetchUser={fetchUser} />
                    </Grid>
                    <Grid item xs={12}>
                        <Description form={form} setFormField={setFormField} fetchUser={fetchUser} />
                    </Grid>
                    <Grid item xs={12}>
                        <Photos form={form} setFormField={setFormField} updateUser={updateUser} />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <Certificates form={form} setFormField={setFormField} updateUser={updateUser} />
                    </Grid>*/}
                    {user?.businessLevel>1 && <Grid item xs={12}>
                        <Videos fieldName={'coachingPrograms'} form={form} setFormField={setFormField} updateUser={updateUser} />
                    </Grid>}
                    {user?.businessLevel>1 && <Grid item xs={12}>
                        <Videos fieldName={'foodPrograms'} form={form} setFormField={setFormField} updateUser={updateUser} />
                    </Grid>}
                    <Grid item xs={12}>
                        <Dictionaries form={form} setFormField={setFormField} updateUser={updateUser} fetchUser={fetchUser} />
                    </Grid>
                    <Grid item xs={12}>
                        {form && <MDButton size={'large'} variant={'contained'} color={'info'} onClick={showPreview}><FormattedMessage id={"pages.profile.preview"}/></MDButton>}
                    </Grid>
                </Grid>}
            </Container>
            <DemoFooter/>
        </>
    );
}

export default Settings;
