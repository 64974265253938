/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import TableCell from "views/examples/account/settings/components/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";


let map = null;

export const LocationsMap = withScriptjs(withGoogleMap(({locations= [], instagramURL, fbURL, ...rest}, a) => {
  const mapRef = useRef()
  const [needFit, setNeedFit] = useState(true)
  const [zoom, setZoom] = useState(12.75)
  const [center, setCenter] = useState({lat: 54.695, lng: 25.27})

  const intl = useIntl()

  useEffect(() => {
    if(mapRef.current?.getBounds) {
      const {google} = window;
      let bounds = mapRef.current.getBounds();
      if(locations.length>0) {
        bounds = new google.maps.LatLngBounds();
      }

      for (let i = 0; i < locations.length; i++) {
        if(locations[i].coordinates) {
          bounds.extend(locations[i].coordinates);
        }
      }
      if(bounds) {
        mapRef.current.fitBounds(bounds)
        if(mapRef.current.getZoom()<10) {
          setZoom(10)
        }
      }
    }
  }, [mapRef]);

  return (<div>
    <div style={{width: '100%', height: 300}} id={'map'}>
      <GoogleMap

          ref={(ref)=>mapRef.current=ref}
          apiKey="AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4"
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry&key=AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4`}
          defaultCenter={center}
          defaultZoom={zoom}
          onIdle={async ()=>{
            const {google} = window;
            console.log(mapRef.current.getDiv())
            const map = new google.maps.Map(mapRef.current.getDiv(), {zoom, center, mapId: 'map1'});
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

            if(needFit) {
              mapRef.current = map;
              mapRef.current.initialZoom = true;

              let bounds = mapRef.current.getBounds();
              if(locations.length>0) {
                bounds = new google.maps.LatLngBounds();
              }
              for (let i = 0; i < locations.length; i++) {
                if (locations[i]?.coordinates?.lat) {
                  const latlng = new window.google.maps.LatLng(locations[i].coordinates.lat, locations[i].coordinates.lng)
                  bounds.extend(latlng);
                  const priceTag = document.createElement('div');
                  priceTag.className = ' price-tag';
                  priceTag.textContent = locations[i].name;
                  console.log(latlng, locations[i])
                  const marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: latlng,
                    content: priceTag,
                  });
                }
              }
              if (bounds) {
                if(locations.length<2 && locations[0]?.coordinates?.lat) {
                  const latlng = new window.google.maps.LatLng(locations[0].coordinates.lat +.0025, locations[0].coordinates.lng-.0025)
                  const latlng2 = new window.google.maps.LatLng(locations[0].coordinates.lat -.0025, locations[0].coordinates.lng+.0025)
                  bounds.extend(latlng);
                  bounds.extend(latlng2);
                }

                console.log('fitBounds', bounds, bounds.getCenter().lat())
                if(bounds.getCenter().lat()!==0) {
                  map.fitBounds(bounds, 50)
                } else {
                  return;
                }

                console.log( mapRef.current.getZoom())
              }
            }
            setNeedFit(false)
            if(locations.length===1) {
              setZoom(5)
            }
            map.setZoom(3)
            setZoom(3)
            console.log( map.getZoom())
          }}
      >
        {locations.map((loc)=>(<Marker
            position={loc.coordinates}
        />))}
      </GoogleMap>
    </div>
  </div>)
}))

function ProfileInfoCard({ title, description, info, social=[], action, shadow, locations, style={} }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  const intl = useIntl()

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => values[key] && (
      <TableRow key={key}>
        <TableCell padding={[1, 1, 1, 0]} width={'30%'}>
          <MDBox lineHeight={1.4}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {intl.formatMessage({id: `common.${label}`})}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell align="left" padding={[1, 1, 1, 0.5]}>
          <MDTypography variant="caption" fontWeight="light">
            {values[key]}
          </MDTypography>
        </TableCell>
      </TableRow>
  ));

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => link?(
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size["2xl"]}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ):null);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }} style={style}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={1} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light" style={{whiteSpace: 'pre-wrap'}}>
            {description}
          </MDTypography>
        </MDBox>
        <MDBox>

          <Table sx={{  border: 'solid 2px white' }}>
            <TableBody>
              {renderItems}
            </TableBody>
          </Table>
          {social.length>0 && <MDBox display="flex" pb={3} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">

            </MDTypography>
            {renderSocial}
          </MDBox>}
          {locations?.length > 0 ? <LocationsMap googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry&key=AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4`} loadingElement={<MDBox/>} locations={locations} containerElement={<div style={{ height: `300px` }} />}
                                      mapElement={<div style={{ height: `100%` }} />}/>: ''}

        </MDBox>
      </MDBox>
    </Card>
  );
}


export default ProfileInfoCard;
