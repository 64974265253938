/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "nouislider/dist/nouislider.min.css";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";
import moment from "moment";
import Swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _, {sortBy} from "lodash";
import {API} from "api";
import {useAuth} from "useAuth";
import {MDDialog} from "components/Dialog";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {blue} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import MDBadge from "components/MDBadge";
import {FormattedMessage, IntlProvider, useIntl} from "react-intl";
import {useDictionaries} from "lib/dictionaries";
import Grid from "@mui/material/Grid";
import MDAvatar from "../../../components/MDAvatar";
import Tooltip from "@mui/material/Tooltip";
import sha256 from "js-sha256";
import {Paper, TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select from "react-select";
import {appLocales} from "../../../useLocale";



const api = new API();

const ModalContent = ({handleClose, event, list, setList, handleSave, user}) => {
    useEffect(()=>{
        //console.log(list)
    }, [list, event])
    const intl = useIntl()
    const {statusesMap} = useDictionaries()
    const [tempList, setTempList] = useState(_.cloneDeep(list));



    const handleUpdate = useCallback((index, status)=>{
        const newList = _.cloneDeep(tempList)
        newList[index].status = status;
        setTempList(newList)
    }, [tempList])

    if(user && event) {
        const activity = user?.dicts?.sports.find(d => d.key === event.activity)?.name[intl.locale];
        const location = user.coachData.locations.find(d=>d.id===event.location)?.name;
        return (
            <div className={'p-4'}>
            <List sx={{pt: 0}} className={'attendee-list-wrapper mb-3'}>
                {sortBy(tempList, d=>!d.status).map((attendee, i) => {
                    return (
                    <ListItem key={attendee.uuid} className={' p-2 mb-4'}>

                        <ListItemText primary={
                            <TableContainer component={({children})=><Paper elevation={3} style={{paddingTop: 16,paddingBottom: 16, border: `solid 1px ${statusesMap[attendee.status].colorRaw}`}}>{children}</Paper> }>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2}>

                                                <div className={'d-flex align-items-center'}>
                                                    <MDAvatar size={'xl'} sx={{
                                                        marginLeft: 2,
                                                        marginRight: 2,
                                                        bgcolor: blue[100],
                                                        color: blue[600]
                                                    }}
                                                              src={attendee.avatar || `https://www.gravatar.com/avatar/${sha256(attendee.email || attendee.phone)}?d=mp&s=250`}>
                                                    </MDAvatar>
                                                    <strong>{`${attendee.firstName} ${attendee.lastName}`}</strong>
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align={'center'} colSpan={2}><b>{attendee.bookingDate}, {attendee.timeStart} - {attendee.timeEnd}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "bookings.status"})}</TableCell>
                                            <TableCell align="right">{attendee.status}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "schedule.sport"})}</TableCell>
                                            <TableCell align="right">{activity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "forms.basic.email"})}</TableCell>
                                            <TableCell align="right">{attendee.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "forms.basic.phone"})}</TableCell>
                                            <TableCell align="right">{attendee.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "calendar.location"})}</TableCell>
                                            <TableCell align="right">{location}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >{intl.formatMessage({id: "bookings.price"})}</TableCell>
                                            <TableCell align="right">&euro;{event.price}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={attendee.clientComment?{}:{border: 'none'}}>{intl.formatMessage({id:  "forms.book.firstSession"})}</TableCell>
                                            <TableCell style={attendee.clientComment?{}:{border: 'none'}} align="right">&euro;{attendee.firstSession?intl.formatMessage({id:'common.yes'}):intl.formatMessage({id:'common.no'})}</TableCell>
                                        </TableRow>
                                        {attendee.clientComment && ( <TableRow>
                                            <TableCell style={{border: 'none'}} colSpan={2}>
                                            <div>{intl.formatMessage({id: "pages.login.client"})} {intl.formatMessage({id: "common.comment"}).toLowerCase()}:</div>
                                            <div className={'text-muted'}><i>{attendee.clientComment}</i></div>
                                            </TableCell></TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }/>
                        <ListItemIcon className={'popup-list-item'}>
                            <div className={'pl-4 d-flex flex-column'}>
                            {list[i].status === 'canceled' &&
                                <MDBox component={'div'} style={{height: 48}}><MDBadge color={'error'} size={'small'}
                                                                                       badgeContent={intl.formatMessage({id: `dialog.canceled`})}/></MDBox>}
                            {list[i].status === 'declined' &&
                                <MDBox component={'div'} style={{height: 48}}><MDBadge color={'error'} size={'small'}
                                                                                       badgeContent={intl.formatMessage({id: `dialog.declined`})}/></MDBox>}
                            {(list[i].status === 'accepted') && <>
                                      <MDButton onClick={()=>handleUpdate(i, 'declined')} style={{marginBottom: 8}} color={'error'} variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.decline`}))}</MDButton>
                            </>}
                            {( list[i].status === 'pending') && <>
                                <MDButton onClick={()=>handleUpdate(i, 'accepted')} style={{minWidth: 150, marginBottom: 8}} color={'success'} variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.accept`}))}</MDButton>
                                <MDButton onClick={()=>handleUpdate(i, 'declined')} style={{marginBottom: 8}} color={'error'} variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.decline`}))}</MDButton>
                            </>}
                            </div>
                        </ListItemIcon>
                    </ListItem>)
                })}
            </List>
                <MDButton fullWidth variant={'contained'} color={'info'} onClick={()=>{
                    setList(tempList);
                    setTimeout(()=>{
                        handleSave(tempList)
                    },100)
                }}><FormattedMessage id={"dialog.save.changes"}/></MDButton>
                <MDButton fullWidth style={{marginTop: 8}} variant={'contained'} color={'secondary'} onClick={()=>{
                    handleClose()
                }}>Cancel</MDButton>
            </div>
        )
    } else {
        return null;
    }
}
const weekdays = {"MON": 1, "TUE": 2, "WED": 3, "THU": 4, "FRI": 5, "SAT": 6, "SUN": 0};

const ConfirmBody = (props) =>  {

    return ( <IntlProvider messages={appLocales[props.locale]} locale={props.locale} defaultLocale="en">
        <ConfirmBodyText {...props}/>
    </IntlProvider>)
}
const ConfirmBodyText = ({attendee, status}) =>  {
    const intl = useIntl();
    return (<div>
        <h4 className={'text-capitalize'}>
            {intl.formatMessage({id: "common.attendee"})}:&nbsp;
            <strong>
                {attendee.firstName} {attendee.lastName}
            </strong>
        </h4>
        <h4>
            {intl.formatMessage({id: "bookings.status"})}:&nbsp;
            <strong className={'text-danger'}>
                {intl.formatMessage({id: `calendar.${status}`})}
            </strong>
        </h4>
    </div>)
}

function CalendarPage() {
    const Swal = withReactContent(Swal2);
    const [bookings, setBookings] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(true);
    const [filter, setFilter] = useState({
        start: moment().utc().set({week: moment().week(), hours:0, minutes: 0, second: 0, milliseconds: 0, weekday: 0}).toDate(),
        end: moment().utc().add({years: 1}).toDate()
    });
    const [selected, setSelected] = React.useState(null);
    const [modalContent, setModalContent] = React.useState(null);
    const [viewType, setViewType] = React.useState('timeGridWeek');
    const [minCardHeight, setMinCardHeight] = useState('100vh');
    let calendarRef = null
    const {user, fetchUser} = useAuth();
    const {statusesDict, statusesMap} = useDictionaries()
    const intl = useIntl()

    const getRef = (ref) => {
        calendarRef = ref;
    }

    const updateBooking = useCallback(async (id, status) => {
        const attendee = events.find(ev=>{
            return ev.bookings.find( b=> b.uuid === id)
        }).bookings.find( b=> b.uuid === id);

        if(status === 'declined') {
            const locale = intl.locale;
            const title = intl.formatMessage({id:'schedule.areyousure'})
            const swalOpts = {
                imageUrl: attendee.avatar,
                imageWidth: 150,
                imageHeight: 150,
                title,
                //html: <ConfirmBody attendee={attendee} status={status} locale={locale}/>,
                showCloseButton: true,
                showCancelButton: false,
                showDenyButton: true,
                focusConfirm: false,
                confirmButtonColor: '#66BB6A',
                cancelButtonColor: '#EF5350',
                confirmButtonText: <span>{intl.formatMessage({id:'common.decline'})}</span>,
                denyButtonText: <span>{intl.formatMessage({id:'common.close'})}</span>,
            }
            //const closeText = intl.formatMessage({id: 'common.close'})

            const msg = `You have successfully ${(status==='accepted'?intl.formatMessage({id:'calendar.accepted'}):intl.formatMessage({id:'calendar.canceled'})).toLowerCase()} upcoming session with “${attendee.firstName}”. He’ll be notified about the status change via email.`;

            Swal.fire(swalOpts).then(async ({isConfirmed, isDenied}) => {
                if (isConfirmed) {
                    await api.put(`bookings/${id}`, {status});
                    Swal.fire('Success!', msg, 'success').then();
                    fetchBookings().then();
                }
                if (isDenied) {
                    Swal.close()
                }
            })
        } else {
            await api.put(`bookings/${id}`, {status});
            const msg = `You have successfully ${(status==='accepted'?intl.formatMessage({id:'calendar.accepted'}):intl.formatMessage({id:'calendar.canceled'})).toLowerCase()} upcoming session with “${attendee.firstName}”. He’ll be notified about the status change via email.`;
            Swal.fire('Success!', msg, 'success').then();
            fetchBookings().then();
        }

    }, [filter, events])

    const renderMembers = (members)=>members.map((member, key) => {

        const memberKey = `member-${key}`;
        return (
            <Tooltip title={member.firstName} style={{marginTop:-1}}><MDAvatar
                key={memberKey}
                src={member.avatar || `https://www.gravatar.com/avatar/${ sha256( member.email||member.phone ) }?d=mp&s=250`}
                alt="member profile"
                size="xs"
                sx={({borders: {borderWidth}, palette: {white}}) => ({
                    border: `${borderWidth[3]} solid ${statusesMap[member.status].colorRaw}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:not(:first-of-type)": {
                        ml: -1.25,
                    },

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                })}
            /></Tooltip>
        );
    });

    const renderSlotLabel = (slotLabel)=>{
        return <div className={'slot-label'}>{slotLabel.text}</div>
    }
    const renderEvent = ({event}) => {
        //console.log('render', event)
        const {extendedProps } = event;
        const {scheduleData, attendees} = extendedProps;
        const numBookings = scheduleData.bookings?.length || 0;
        const isFull = ((scheduleData.maxUsers || 1) - numBookings) < 1;
        const coach = user
        const activity = user.dicts?.sports.find(d=>d.key===scheduleData.activity)?.name[intl.locale]
        const employee = coach.coachData.employees.find(d=>d.id===scheduleData.employee)?.name || coach.fullname || coach.name
        const location = coach.coachData.locations.find(d=>d.id===scheduleData.location)?.name
        const descr = <>{intl.formatMessage({id: "common.sport"})}: {activity}<br/>
            {intl.formatMessage({id: 'common.coach'})}: {employee}<br/>
            {intl.formatMessage({id: 'forms.book.location'})}: {location}</>

       if(viewType!=='timeGridWeek') {
           const attendee = extendedProps.owner;
           const status = statusesMap[extendedProps.status];
           const numBookings = bookings.filter(b=>b.scheduleId===extendedProps.scheduleId).length;
           return (
               <Grid className={'cursor-pointer timeGridWeek-item'} container>
                   <Grid item px={2} sx={{paddingBottom: {xs: 3, sm: 0}}}
                         style={{alignSelf: 'center', textAlign: 'center'}} xs={12} sm={5} lg={3} xl={2}>
                       <MDAvatar
                           src={event.extendedProps.image}
                           alt={event.title}
                           size={'xxl'}
                           sx={{margin: 'auto'}}
                       />
                       <div style={{width: 120, fontSize:16, position: 'absolute', left: 6, marginTop: -100 }}>
                           <strong><FormattedMessage id={'common.spaces.left'}/></strong>
                           <div className={'text-dark'} style={{fontWeight:500, fontSize: 24}}>{numBookings>scheduleData.maxUsers?scheduleData.maxUsers:scheduleData.maxUsers}/{scheduleData.maxUsers}</div>
                       </div>
                   </Grid>
                   <Grid item xs={12} sm={6}>
                       <div>
                           <MDTypography className={''}
                                         style={{lineHeight: 1.25}} component={'div'} variant={'subtitle2'}>
                               <Grid container spacing={1}>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                       <FormattedMessage id={"schedule.sport"}/>
                                   </Grid>
                                   <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex"
                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                       <strong>{activity}</strong>
                                   </Grid>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                       <FormattedMessage id={"pages.login.client"}/>
                                   </Grid>
                                   <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex"
                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                       <strong>{attendee.fullname}</strong>
                                   </Grid>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                       <FormattedMessage id={"calendar.location"}/>
                                   </Grid>
                                   <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex"
                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                       <strong>{location}</strong>
                                   </Grid>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                       <FormattedMessage id={"bookings.price"}/>
                                   </Grid>
                                   <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex"
                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                       <strong>&euro;{scheduleData.price}</strong>
                                   </Grid>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                       <FormattedMessage id={"forms.book.firstSession"}/>
                                   </Grid>
                                   <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex"
                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                       <strong>{extendedProps.firstTime?intl.formatMessage({id:'common.yes'}):intl.formatMessage({id:'common.no'})}</strong>
                                   </Grid>
                                   <Grid item xs={6} className={'p-2 border-dark border-bottom-025'} >
                                       <FormattedMessage id={"bookings.status"}/>
                                   </Grid>
                                       <Grid xs={6} className={'p-2 border-dark border-bottom-025'}  item display="flex" flexDirection="column" alignItems="flex-start"
                                             justifyContent="center">
                                           <strong>{status.label}</strong>
                                       </Grid>
                                   {extendedProps.clientComment && (<Grid item xs={6}>
                                       {intl.formatMessage({id: "bookings.client.comment"})}
                                   </Grid>)}
                                   {extendedProps.clientComment && (
                                       <Grid xs={6} item display="flex"
                                             flexDirection="column" alignItems="flex-start" justifyContent="center">
                                           <div className={'text-muted'}><i>{extendedProps.clientComment}</i></div>
                                       </Grid>)}

                               </Grid>
                           </MDTypography>
                       </div>

                       {/*<div>


                           <ListItem  className={' p-2 mb-4'}>

                               <ListItemText primary={
                                   <TableContainer component={({children})=><Paper elevation={3} style={{paddingTop: 16,paddingBottom: 16, color: '#000000', border: `solid 1px ${status.colorRaw}`}}>{children}</Paper> }>
                                       <Table>
                                           <TableBody>
                                               <TableRow>
                                                   <TableCell><FormattedMessage id={ "pages.login.client"}/></TableCell>
                                                   <TableCell align="right">
                                                          <strong> {`${attendee.fullname}`}</strong>
                                                   </TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell><FormattedMessage id={ "schedule.datetime"}/></TableCell>
                                                   <TableCell align={'right'}><b>{extendedProps.bookingDate}, {scheduleData.startTime} - {scheduleData.endTime}</b></TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell>{intl.formatMessage({id: "bookings.status"})}</TableCell>
                                                   <TableCell align="right">{status.label}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell>{intl.formatMessage({id: "schedule.sport"})}</TableCell>
                                                   <TableCell align="right">{activity}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell>{intl.formatMessage({id: "forms.basic.email"})}</TableCell>
                                                   <TableCell align="right">{attendee.email}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell>{intl.formatMessage({id: "forms.basic.phone"})}</TableCell>
                                                   <TableCell align="right">{attendee.phone}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell>{intl.formatMessage({id: "calendar.location"})}</TableCell>
                                                   <TableCell align="right">{location}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell >{intl.formatMessage({id: "bookings.price"})}</TableCell>
                                                   <TableCell align="right">&euro;{extendedProps.price}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell style={extendedProps.clientComment?{}:{border: 'none'}}>{intl.formatMessage({id:  "forms.book.firstSession"})}</TableCell>
                                                   <TableCell style={extendedProps.clientComment?{}:{border: 'none'}} align="right">{extendedProps.firstTime?intl.formatMessage({id:'common.yes'}):intl.formatMessage({id:'common.no'})}</TableCell>
                                               </TableRow>
                                               {extendedProps.clientComment && ( <TableRow>
                                                   <TableCell style={{border: 'none'}} colSpan={2}>
                                                       <div>{intl.formatMessage({id: "pages.login.client"})} {intl.formatMessage({id: "common.comment"}).toLowerCase()}:</div>
                                                       <div className={'text-muted'}><i>{extendedProps.clientComment}</i></div>
                                                   </TableCell></TableRow>)}
                                           </TableBody>
                                       </Table>
                                   </TableContainer>
                               }/>
                               <ListItemIcon className={'popup-list-item'}>
                                   <div className={'pl-4 d-flex flex-column'}>
                                       {(extendedProps.status === 'accepted' || extendedProps.status === 'pending') && <>
                                           <MDButton onClick={()=>updateBooking(extendedProps.uuid, 'declined')} style={{minWidth: 150, marginBottom: 8}} color={'error'} variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.decline`}))}</MDButton>
                                       </>}
                                       {(extendedProps.status === 'pending') && <>
                                           <MDButton onClick={()=>updateBooking(extendedProps.uuid, 'accepted')} style={{minWidth: 150, marginBottom: 8}} color={'success'} variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.accept`}))}</MDButton>
                                       </>}

                                   </div>
                               </ListItemIcon>
                           </ListItem>


                       </div>*/}
                   </Grid>
                   <Grid className={'popup-list-item'} item px={2} sx={{paddingBottom: {xs: 3, sm: 0}}}
                         style={{alignSelf: 'center', textAlign: 'center'}} xs={12} sm={5} lg={3} xl={2}>
                       <div className={'pl-4 d-flex flex-column'}>
                           {(extendedProps.status === 'accepted' || extendedProps.status === 'pending') && <>
                               <MDButton onClick={() => updateBooking(extendedProps.uuid, 'declined')}
                                         style={{minWidth: 150, marginBottom: 8}} color={'error'}
                                         variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.decline`}))}</MDButton>
                           </>}
                           {(extendedProps.status === 'pending') && <>
                               <MDButton onClick={() => updateBooking(extendedProps.uuid, 'accepted')}
                                         style={{minWidth: 150, marginBottom: 8}} color={'success'}
                                         variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.accept`}))}</MDButton>
                           </>}
                           <MDButton onClick={() => {
                               Swal.fire({
                                   title: intl.formatMessage({id: `common.client.contacts`}),
                                   html: "<div class='font-bigger'>" +
                                       "<p>" +
                                       intl.formatMessage({id: 'common.email'}) + ": " + attendee.email +
                                       "</p>" +
                                       "<p>" +
                                       intl.formatMessage({id: 'common.phone'}) + ": " + attendee.phone +
                                       "</p>" +
                                       "</div>",
                                   icon: "info"
                               });
                           }}
                                     style={{minWidth: 150, marginBottom: 8}} color={'secondary'}
                                     variant={'contained'}>{_.capitalize(intl.formatMessage({id: `common.client.contacts`}))}</MDButton>
                       </div>
                   </Grid>
               </Grid>
           )
       } else {
           if (event.display === 'background') {
               return (
                   <Grid container
                         className={`event-common p-1 ${!isFull ? 'mb-n11' : 'event-full'} ${(!isFull && numBookings) > 0 ? 'has-bookings' : ''}`}
                   >
                       <Grid item sm={11}>
                           <MDTypography component={'div'} style={{lineHeight: 1.1}} variant={'overline'}>
                               {moment(event.start).format("HH:mm")}<span
                               className={'d-none d-lg-inline'}> - {moment(event.start).add(1, 'h').format("HH:mm")}</span>
                           </MDTypography>


                           <div className={'d-md-flex align-items-baseline'}>
                               <MDTypography className={'text-bottom trim-vertical trim-vertical-3 text-event-desc'}
                                             style={{lineHeight: 1, zoom: 0.7}} component={'div'} variant={'subtitle2'}>
                                   {descr}
                               </MDTypography>
                           </div>
                       </Grid>
                       <Grid item sm={3} className={'d-none d-md-flex align-items-baseline'}>

                       </Grid>

                   </Grid>
               )
           } else {
               return (
                   <Grid container
                         className={`cursor-pointer  p-1 ${!isFull ? 'mb-n11' : 'event-full'} ${(!isFull && numBookings) > 0 ? 'has-bookings' : ''}`}
                         onClick={() => showEventPopup({...event, descr, attendees, scheduleData})}>
                       <Grid item sm={11}>
                           <MDTypography component={'div'} style={{lineHeight: 1.1}} variant={'button'}>
                               {moment(event.start).format("HH:mm")}<span
                               className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span>
                               &nbsp;&nbsp;<b>{activity}</b>
                           </MDTypography>

                           <div className={'d-flex align-items-baseline'}>
                               <MDTypography className={'text-bottom trim-vertical trim-vertical-3 text-event-desc'}
                                             style={{lineHeight: 1.1, zoom: 0.9}} component={'div'}
                                             variant={'subtitle2'}>
                                   <>
                                       {intl.formatMessage({id: 'forms.book.location'})}: {location}
                                   </>

                               </MDTypography>
                           </div>
                       </Grid>
                       <div className={'session-members-list'}>
                           <MDTypography component={'div'} style={{lineHeight: 1, marginRight: 14}}
                                         variant={'overline'}>
                               <FormattedMessage
                                   id={"common.attendees.of"}/>: {(!isFull && attendees?.length > 0) ? (<>{attendees?.length} <FormattedMessage
                               id={"common.of"}/> {scheduleData.maxUsers}</>) : ''}
                           </MDTypography>
                       </div>

                   </Grid>
               );
           }
       }
    }

    const fetchEvents = useCallback(async () => {
        setLoading(true)
        const schedules = (await api.get(`schedules/${user.uuid}/list`, filter)) || [];
        const newEvents = schedules.map(schedule => ({
            scheduleData: schedule,
            bookings: schedule.bookings,
            id: schedule.uuid,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            groupId: schedule.uuid,
            daysOfWeek: [weekdays[schedule.weekDay]],
            startDate: undefined,
            title: `Preview`,
            start: undefined,
            end: undefined,
            day: schedule.weekDay,
            editable: false,
            display: 'background',
            className: `event-schedule`,
            status: 'pending',
            isPreview: false,
        }))
        setEvents(newEvents)
        setLoading(false)

    }, [user, filter])

    const fetchBookings = useCallback(async () => {
        const bookingsRes = await api.get(`bookings`, filter) || [];
        const mappedBookings = bookingsRes.map(b=>({
            ...b,
            image: b.owner.avatar,
            start: `${b.bookingDate}T${(b.timeStart)}:00`,
            end: `${b.bookingDate}T${b.timeEnd}:00`,
            endTime: null,
            startTime: null,
            eventId: `${b.uuid}`,
            display: 'list-item',
            className: `event-${statusesDict.find(s=>s.status===b.status)?.color}`
        }))
        if(viewType !== 'timeGridWeek') {
            setBookings(mappedBookings)
            return mappedBookings;
        }
        const groupedBookings = _.reduce(_.groupBy(mappedBookings, b=>b.scheduleId),(arr, b)=>{
            const item = arr.find(d=>d.scheduleId===b[0].scheduleId)
            if(!item) {
                const obj = {
                    ...b[0],
                    attendees: b,
                    status: b.find(d=>d.status==='pending')?'pending':b[0].status
                }
                arr.push(obj)
            } else {
                item.attendees.push(b)
            }
            if(arr[arr.length-1].maxUsers>1){
                arr[arr.length-1].className = `event-info`
            }
            return arr;
        }, [])
        console.log(groupedBookings)
        setBookings(groupedBookings)
    }, [viewType, filter]);

    useEffect(()=>{
        fetchEvents()
        fetchBookings()
    }, [filter])



    const setFilterField = (field, value) => {
        setFilter(f=>({...f, [field]: value}))
    }
    useEffect(() => {
        const calendarAPI = calendarRef?.getApi?.()
        if (calendarAPI) {
            calendarAPI.on('_resize', () => {
                const api = calendarRef?.getApi?.()
                console.log(api.currentData?.dateProfile)
                if(api.currentData?.dateProfile?.activeRange) {
                    const range = _.cloneDeep(api.currentData.dateProfile.activeRange);
                    if (!moment(filter.start).isSame(moment(range.start))) {
                        setFilter(f=>({...f, ...api.currentData.dateProfile.activeRange}))
                        calendarAPI.off('_resize')
                    }
                }

                if(api.currentData?.currentViewType === "listWeek") {
                    setShowFilter(true)
                }else{
                    setShowFilter(false)
                }
                api.currentData.currentViewType === "timeGridWeek" ? api.currentClassNames.push('week-view') : _.pull(api.currentClassNames, 'week-view')
                setViewType(api.currentData.currentViewType)
            })

        }
    }, [calendarRef, filter, setShowFilter])

    useEffect(() => {
        console.log(calendarRef)
        const calendarAPI = calendarRef?.getApi?.()

        console.log(calendarAPI.currentData?.currentViewType)
        if(calendarAPI.currentData?.currentViewType === "listWeek") {
            setShowFilter(true)
        }else{
            setShowFilter(false)
        }
    }, [calendarRef])

    document.documentElement.classList.remove("nav-open");

    const [open, setOpen] = React.useState(false);

    const [list, setList] = useState([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = useCallback(async (newList) => {
        await Promise.all(newList.map(async(b)=>{
            await api.put(`bookings/${b.uuid}`, {status: b.status})
        }))
        await fetchEvents();
        await fetchBookings();
        setSelected(null)
        setModalContent(null);
        setOpen(false);
    }, [list]);

    const statusOptions = useMemo(()=>statusesDict.filter(d=>!d.hidden).map(s=>({
        label: `${s.label} (${bookings.filter(b=>b.status===s.status).length})`,
        value: s.status
    })), [bookings])


    const showEventPopup = useCallback(({scheduleData, attendees}) => {
        setList([])
        setSelected(null)
        setList(attendees)
        setSelected(scheduleData)
        setModalContent(<ModalContent handleClose={handleClose}  event={scheduleData} list={attendees} setList={setList} user={user}
                                      handleSave={handleSave}/>)
        handleClickOpen();
    }, [selected, list, user, handleSave, setList])

    useEffect(()=>{
        if(list && selected) {
            setModalContent(<ModalContent handleClose={handleClose} event={selected} list={list} setList={setList} user={user}
                                          handleSave={handleSave}/>)
        }
    }, [list, user, selected, setList, handleSave])

    const data = useMemo(()=>{
        let tempData = [];
        if(viewType === 'timeGridWeek') {
            tempData = [...bookings.filter(b=>filter.status?filter.status===b.status:true).map(b=>({
                ...b.scheduleData,
                ...b,
                id: b.uuid,
                image: b.coach.avatar,
                start: `${b.bookingDate}T${(b.timeStart)}:00`,
                end: `${b.bookingDate}T${b.timeEnd}:00`,
                endTime: null,
                startTime: null,
                eventId: `${b.uuid}`,
                display: 'list-item'
            })),...events];
        } else {
            tempData = bookings.filter(b=>filter.status?filter.status===b.status:true).map(b=>({
                ...b,
                id: b.uuid,
                image: b.owner.avatar,
                start: `${b.bookingDate}T${(b.timeStart)}:00`,
                end: `${b.bookingDate}T${b.timeEnd}:00`,
                endTime: null,
                startTime: null,
                eventId: `${b.uuid}`,
                display: 'list-item'
            }))
        }
        return filter.status?tempData:sortBy(tempData, d=>!d.status);
    }, [viewType, events, bookings])


    return (
        <>
            <ExamplesNavbar/>
            <MDDialog
                noWrapper={true}
                title={intl.formatMessage({id: "calendar.modal.bookings"})}
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                content={modalContent}
            />



            <div style={{height: '100%', marginTop: 140, minWidth: 410, minHeight: 17*60}} className={'px-0 '}>
                <div className={"my-5 px-0 px-lg-2 px-xl-5 coach-calendar"}  style={{height: '100%', minHeight: 17*60}}>
                    <Calendar
                        noCard={false}
                        datesSet={(e)=>{
                            const api = calendarRef?.getApi?.()

                            if(api?.currentData?.dateProfile?.activeRange) {
                                console.log(api?.currentData?.dateProfile, e)
                                const range = _.cloneDeep(api.currentData.dateProfile.activeRange);
                                if (!moment(filter.start).isSame(moment(range.start))) {
                                    setFilter(f=>({...f, ...api.currentData.dateProfile.activeRange}))
                                }
                            }
                        }}
                        cardHeight={minCardHeight}
                        themeSystem={'bootstrap5'}
                        events={data}
                        passRef={getRef}
                        header={{title: intl.formatMessage({id: "calendar.header.coach"})}}
                        height={'100%'}
                        initialView={'listWeek'}
                        allDaySlot={false}
                        dayMinWidth={200}

                        timeZone={'Europe/Vilnius'}
                        slotDuration={'01:00'}
                        slotMinTime={'06:00'}
                        slotMaxTime={'23:00'}
                        expandRows={true}
                        stickyHeaderDates={true}
                        slotMinWidth={200}
                        buttonText={{
                            listWeek: intl.formatMessage({id: "bookings.list"}),
                            listMonth: intl.formatMessage({id: "bookings.list"}),
                            listYear: intl.formatMessage({id: "bookings.list"}),
                            timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                            today: intl.formatMessage({id: "bookings.thisweek"})
                        }}
                        headerToolbar={{
                            start: 'title',
                            center: 'alert1',
                            end: 'listWeek timeGridWeek prev next'
                        }}
                        slotLabelFormat={(date) => {
                            return `${date.start.hour.toString().padStart(2, '0')}:00　`
                        }}
                        eventContent={renderEvent}
                        slotLabelContent={renderSlotLabel}
                        selectable={false}
                        showFilter={showFilter}
                    >
                        {showFilter && <MDBox sx={{
                            marginTop: {
                                xs: '60px', sm: '40px', md: '20px', lg: '0'
                            }
                        }}>

                            <label className={'text-black'} style={{paddingBottom: 20}}>{intl.formatMessage({id: "bookings.status"})}:</label>
                            <Select

                                styles={{
                                    container: (baseStyles)=>({...baseStyles, width: 300, marginTop: -30, zIndex: 999})
                                }}
                                onChange={({value})=>{
                                    setFilter(f=>({...f, status: value}))
                                }}
                                value={filter.status?statusOptions.find(s=>s.value===filter.status):{label: intl.formatMessage({id: "common.all"}), value: null}}
                                options={[{label: intl.formatMessage({id: "common.all"}), value: null}, ...statusOptions]}
                            />
                        </MDBox>}
                    </Calendar>



                </div>
            </div>
            <DemoFooter/>
        </>
    );
}

export default CalendarPage;
