import FullCalendarComponent from "@fullcalendar/react";
import React, {forwardRef, useEffect, useState} from "react";
import moment from "moment/moment";

export const FullCalendar = forwardRef(({children, ...props}, ref) =>{
    const [title, setTitle] = useState('')
    useEffect(() => {
        document.querySelector('.fc-toolbar-title').innerHTML ='&nbsp;'+title
                .replace(
                    "{{NL}}",
                    '<br/>&nbsp;'
                )
        document.querySelector('.fc-toolbar-title').style.width = '105px'
    }, [title]);

    return (
        <FullCalendarComponent
            {...(ref?{ref}:{})}
            views={{
                timeGridWeek: { // name of view
                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                        return ``
                    }
                },
                dayGridMonth: { // name of view
                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                        return ``
                    }
                },
                listWeek: { // name of view
                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                        return ``
                    }
                }
            }}

            {...props}
        >{children || null}</FullCalendarComponent>
    )
})
