import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyDown from "@mui/icons-material/KeyboardArrowDown";
import {useIntl} from "react-intl";
import AccordionDetails from "@mui/material/AccordionDetails";
import {WeeklyHours} from "../WeeklyHours";
import React, {useEffect, useState} from "react";
import MDButton from "../../../components/MDButton";

export const AvailabilityEditor = ({schedules, editorRef, tempEvents, setTempEvents, setSchedules, fetchSchedules, setExpanded,  expanded}) => {
    const [open, setOpen] = useState(true);

    useEffect(()=>{
        setOpen(true);
        return ()=>setOpen(false)
    }, [])

    const intl = useIntl()
    const handleClose = () => setExpanded(false);
    return (

            <div className={expanded?'px-0 pt-2 pb-3':''} style={{ overflowY:'auto', height: expanded?'auto':'0', maxHeight: expanded?'100vh':'0',}}>
                {/*<IconButton onClick={handleClose} style={{position:'absolute', right:6, top: -2}}><CloseIcon color={'info'} fontSize={'large'}/></IconButton>*/}
     <Accordion slotProps={{transition: {duration: 200}}}
                style={{
                    borderRadius: 20,
                    zIndex:2,
                    backgroundColor: 'transparent',
                    marginTop: -24,
                    borderShadow:'none',
                    boxShadow:'none',
     }}
                {...{expanded}} onChange={()=>setExpanded(exp=>!exp)}>
            <AccordionSummary
                expandIcon={
                    <KeyDown
                        fontSize={'large'}
                        color={'white'}
                    />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    borderShadow:'none',
                    height: 8,
                    boxShadow:'none',
                    padding: 0
                }}
            >

            </AccordionSummary>
            <AccordionDetails style={{borderRadius: 10, padding: 2, paddingBottom: 20}}>
                <WeeklyHours
                    setExpanded={setExpanded}
                    fetchSchedules={fetchSchedules}
                    setSchedules={setSchedules}
                    schedules={schedules}
                    ref={editorRef}
                    tempEvents={tempEvents}
                    setTempEvents={setTempEvents}
                />
            </AccordionDetails>
        </Accordion>
                <div className={'text-center'}>
                    <MDButton color={'secondary'} onClick={handleClose} variant={'contained'}>Close</MDButton>
                </div>
            </div>
    )
}
