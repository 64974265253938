import React, {useCallback, useEffect, useMemo, useState} from "react";

import "nouislider/dist/nouislider.min.css";
import 'react-phone-number-input/style.css'

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";
import _ from "lodash";

import Grid from "@mui/material/Grid";
import {useAuth} from "useAuth";
import {API} from "api";

import Moment from "moment";
import {extendMoment} from 'moment-range';
import {FormattedMessage, useIntl} from "react-intl";
import Switch from "@mui/material/Switch";
import {MDDialog} from "../../../components/Dialog";
import ComplexProjectCard from "../Cards/ProjectCards/ComplexProjectCard";
import {ChevronLeft, ChevronRight, Info} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Input} from "reactstrap";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MDAlert from "../../../components/MDAlert";
import Swal2 from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {useLocale} from "../../../useLocale";

const MySwal = withReactContent(Swal);

const initialScreenSize = window.innerWidth;

const moment = extendMoment(Moment);

const api = new API()
const weekdays = {"MON": 1, "TUE": 2, "WED": 3, "THU": 4, "FRI": 5, "SAT": 6, "SUN": 0};
moment.updateLocale('en', {
    week : {
        dow : 1,
        doy: 4
    }
});

export function BookingCalendar({coach, setLoading, children}) {
    const intl = useIntl()

    const {locale, setLocale} = useLocale()
    const [events, setEvents] = useState([]);
    const [hideFull, setHideFull] = useState(false);
    const [initiated, setInitiated] = useState(false);
    const [filter, setFilter] = useState({
            start: moment().utc().set({week: moment().week(), hours:0, minutes: 0, second: 0, milliseconds: 0, weekday: 0}).toDate(),
            end: moment().utc().set({week: moment().week(), hours:0, minutes: 0, second: 0, milliseconds: 0, weekday: 6}).toDate()
    });
    const {user} = useAuth();
    const minCardHeight = '100vh';
    let calendarRef = null;
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const handleClose = () => {
        setOpen(false);
    };

    const getRef = (ref) => {
        calendarRef = ref;
    }

    const getEvents = useCallback(async () => {

        setLoading(true)
        const schedules = (await api.get(`schedules/${coach.uuid}/list`, filter)) || [];
        const newEvents = schedules.map(schedule => ({
            schedule,
            bookings: schedule.bookings,
            id: schedule.uuid,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            groupId: schedule.uuid,
            daysOfWeek: [weekdays[schedule.weekDay]],
            startDate: undefined,
            title: `Preview`,
            start: undefined,
            end: undefined,
            day: schedule.weekDay,
            editable: false,
            display: 'list-item',
            className: `event-schedule`,
            status: 'pending',
            isPreview: false,
        }))
        setEvents(newEvents)
        setLoading(false)

    }, [user, filter])

    useEffect(() => {
        if (coach && !isCoach) {
            getEvents();
        }
    }, [getEvents]);

    useEffect(() => {
        const calendarAPI = calendarRef?.getApi?.()
        if (calendarAPI) {
            calendarAPI.on('_resize', () => {
                const api = calendarRef?.getApi?.()
                //setMinCardHeight(api.currentData.currentViewType === "timeGridWeek"?'100vh':1280)
                if(api.currentData?.dateProfile?.activeRange) {
                    const range = _.cloneDeep(api.currentData.dateProfile.activeRange);
                    if (!moment(filter.start).isSame(moment(range.start))) {
                        setFilter(f=>({...f, ...api.currentData.dateProfile.activeRange}))
                        calendarAPI.off('_resize')
                    }
                }
                api.currentData.currentViewType === "timeGridWeek" ? api.currentClassNames.push('week-view') : _.pull(api.currentClassNames, 'week-view')
                let zoom=0;
                if(window.innerWidth<=1260) {
                    zoom='90%'
                }
                if(window.innerWidth<=1024) {
                   zoom='80%'
                }
                if(window.innerWidth<=900) {
                  zoom='75%'
                }
                if(window.innerWidth<=700) {
                  zoom='65%'
                }
                setTimeout(()=>{
                    if(zoom && document.querySelector('.fc').style.zoom!==zoom) {
                        document.querySelector('.fc').style.zoom = zoom;
                        const fcSize = document.querySelector('.fc').offsetWidth;
                        if(fcSize>initialScreenSize) {
                            setTimeout(()=> {
                                const style = document.querySelector('.profile-calendar .fc-timegrid').style;
                                style.setProperty('--rightoffset', `0px`);
                                document.querySelector('.profile-calendar .fc-timegrid')?.classList.remove('with-scroll')
                                document.querySelector('.scroll-indicator')?.classList.remove('with-scroll')
                                document.querySelector('.profile-calendar .fc-timegrid')?.classList.add('with-scroll')
                                document.querySelector('.scroll-indicator')?.classList.add('with-scroll')
                                style.setProperty('animation', '')
                            }, 800);
                        }
                    }
                }, 650);
            })

            if(!initiated) {
                let eventsAvailable =
                    events
                        .filter((ev) => {
                                return moment()
                                    .startOf('week')
                                    .set('day', ev.schedule.weekDay)
                                    .set('hour', ev.startTime.split(":")[0])
                                    .set('minutes', ev.startTime.split(":")[1]).isAfter(new Date())

                            }
                        )
                console.log({eventsAvailable})
                if (eventsAvailable.length < 1) {
                    eventsAvailable = events
                        .filter((ev) => {
                                return moment()
                                    .set('day', ev.schedule.weekDay)
                                    .set('hour', ev.startTime.split(":")[0])
                                    .set('minutes', ev.startTime.split(":")[1]).isAfter(new Date())
                            }
                        )
                }
                const firstAvailable = _.minBy(eventsAvailable, (ev) =>
                    moment()
                        .set('day', ev.schedule.weekDay)
                        .set('hour', ev.startTime.split(":")[0])
                        .set('minutes', ev.startTime.split(":")[1])
                )
                if (firstAvailable) {
                    console.log({
                        firstAvailable, eventsAvailable, calendarAPI, goto: moment()
                            .set('day', firstAvailable.schedule.weekDay)
                            .set('hour', firstAvailable.startTime.split(":")[0])
                            .set('minutes', firstAvailable.startTime.split(":")[1])
                    })
                    calendarAPI.gotoDate(moment()
                        .set('day', firstAvailable.schedule.weekDay)
                        .set('hour', firstAvailable.startTime.split(":")[0])
                        .set('minutes', firstAvailable.startTime.split(":")[1]).toDate())
                    setInitiated(true)
                }
            }
        }
    }, [calendarRef, filter, events])


    document.documentElement.classList.remove("nav-open");

    const renderSlotLabel = (slotLabel) => {
        return <div className={'slot-label'}>{slotLabel.text}</div>
    }
    const renderEventData = useCallback((event) => {
        const {schedule} = event.extendedProps;
        const loc = _.find(coach.coachData?.locations, {id: schedule.location})?.name;
        return <Paper className={'text-justify'} elevation={4} style={{minWidth: 300}}>
            <Table>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "schedule.datetime"})}</TableCell>
                    <TableCell align={'right'}>{moment(event.start).format('YYYY-MM-DD')}<br/>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "schedule.sport"})}</TableCell>
                    <TableCell align="right">{_.find(user.dicts?.sports, {key: schedule.activity})?.name[intl.locale]}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "calendar.location"})}</TableCell>
                    <TableCell align="right">{loc}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'} style={schedule.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "bookings.price"})}</TableCell>
                    <TableCell style={schedule.comment?{}:{border: 'none'}} align="right">&euro;{schedule.price}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'} style={schedule.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "common.coach"})}</TableCell>
                    <TableCell style={schedule.comment?{}:{border: 'none'}} align="right">{coach.fullname}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder text-capitalize'} style={schedule.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "common.attendees"})}</TableCell>
                    <TableCell style={{border: 'none'}} align="right">{schedule.bookings.length} <FormattedMessage
                        id={"common.of"}/> {schedule.maxUsers}</TableCell>
                </TableRow>
            </Table>
            {schedule.comment && ( <div className={'p-2'}>
                    <div className={'font-weight-bolder'}>{intl.formatMessage({id: "common.comment"})}:</div>
                    <div className={'comments-box'} dangerouslySetInnerHTML={{__html: schedule.comment}}></div>
                </div>
                )}
        </Paper>
    }, [locale, intl])
    const renderEvent = ({event}) => {
        return <Tooltip
            followCursor={false}
            enterDelay={2000}
            enternextDelay={3000}
            classes={{
                popper: 'grid-tooltip',
                tooltip: 'bg-transparent p-0 m-0 border-0 mt-4',
                arrow: 'arrow-white',
            }}
            title={renderEventData(event)}>
            {renderEventFunc({event})}
        </Tooltip>
    }
    const renderEventFunc = ({event}) => {
        //console.log('render', event)
        const {schedule} = event.extendedProps;
        const loc = _.find(coach.coachData?.locations, {id: schedule.location})?.name;
        const numBookings = event.extendedProps.bookings?.length || 0;
        const isFull = ((schedule.maxUsers || 1) - numBookings) < 1;
        const isPast = moment().isAfter(moment(event.end));

        return (
            <div>
                <MDBox
                    className={`event-common ${isPast ? 'event-common-past' : ''} ${!isFull ? 'mb-n11' : 'event-full'} ${(!isFull && numBookings)>0?'has-bookings':''}`}
                    onClick={() => {
                        if(isFull) {
                            Swal.fire('Warning!', 'This slot is already booked', 'warning')
                        } else if (numBookings>0 && event.extendedProps.bookings.find(b=>b.owner===user.uuid)) {
                            Swal.fire('Warning!', 'You already have booked this slot', 'warning')
                        } else if (isPast) {
                            Swal.fire('Warning!', 'This slot is already happened', 'warning')
                        } else {
                            eventClickHandler(event)
                        }
                    }}>
                    <MDBox className={'label-box-schedule p-1 text-dark'}>
                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1.1, marginTop: -4}}
                                      variant={'overline'}>
                            <MDTypography className={'label-box-title'} color={'inherit'} component={'span'} style={{
                                lineHeight: 1.2,
                            }}
                                          variant={'h6'}>{schedule.startTime} &nbsp;{_.find(user.dicts?.sports, {key: schedule.activity})?.name[intl.locale]}
                                {schedule.price ? <span
                                    className={'font-weight-bold'}> &euro;{schedule.price}</span>:''}
                            </MDTypography>
                            {schedule.location && loc && <MDTypography color={'inherit'} component={'div'}
                                                                       style={{lineHeight: 1.2}}
                                                                       variant={'caption'}>
                            {intl.formatMessage({id: "calendar.location"})}: {loc}</MDTypography>}

                        </MDTypography>
                        {schedule.maxUsers>1?(<MDTypography color={'inherit'} component={'div'}
                                      style={{lineHeight: 1.3, fontWeight: numBookings>0?600:400}}
                                      variant={'caption'}>{numBookings} <FormattedMessage
                            id={"common.of"}/> {schedule.maxUsers} <FormattedMessage
                            id={"common.attendees"}/></MDTypography>):(<MDTypography color={'inherit'} component={'div'}
                                                                                     style={{lineHeight: 1.3, fontWeight: numBookings>0?600:400}}
                                                                                     variant={'caption'}><FormattedMessage
                                id={"common.personal"}/></MDTypography>)}

                    </MDBox>
                </MDBox>
            </div>
        );
    }

    const isCoach = useMemo(() => coach.uuid === user.uuid && !coach.isPreview, [user, coach])

    const handleBookSlot = useCallback(async (event) => {
        const {schedule} = event.extendedProps;
        setModalContent(<ComplexProjectCard
            style={{zIndex: 999999999999}}
            image={coach.avatar}
            title={intl.formatMessage({id: 'weekly.confirmation', defaultMessage: 'Training confirmation'})}
            description={null}
            dateTime={moment(event.start).format()}
            footer={(
                <MDBox style={{textAlign: 'right', marginRight: 24}}>
                    <MDButton color={'error'} variant={'contained'} style={{marginRight: 16}}
                              onClick={() => eventClickHandler(event)}><ChevronLeft className={'text-bigger'}/> Back</MDButton>
                    <MDButton onClick={() => handleBookSlotComplete(event)} color={'success'}
                              variant={'contained'}>Confirm <ChevronRight className={'text-bigger'}/></MDButton>
                </MDBox>
            )}
            members={[]}
        >

            <Grid container spacing={3} m={1}>
                <Grid item xs={5} className={'font-weight-bold'}><FormattedMessage id={'common.name'}/></Grid>
                <Grid item xs={7}>{user.name}</Grid>

                <Grid item xs={5} className={'font-weight-bold'}><FormattedMessage id={'common.surname'}/></Grid>
                <Grid item xs={7}>{user.surname}</Grid>

                <Grid item xs={5} className={'font-weight-bold'}><FormattedMessage id={'common.email'}/></Grid>
                <Grid item xs={7}>{user.email}</Grid>

                <Grid item xs={5} className={'font-weight-bold'}><FormattedMessage id={'forms.basic.phone'}/></Grid>
                <Grid item xs={7}>{user.phone}</Grid>

                <Grid item xs={5} className={'font-weight-bold'}>{intl.formatMessage({id: "forms.book.firstSession"})} </Grid>
                <Grid item xs={7}>
                        <RadioGroup row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className={'weekly-confirm-radio-group'}
                            defaultValue={true}
                        >
                            <FormControlLabel value={true} control={<Radio id={'firstTime'}/>} label={intl.formatMessage({id: "common.yes"})}/>
                            <FormControlLabel value={false} control={<Radio/>} label={intl.formatMessage({id: "common.no"})}/>
                        </RadioGroup>
                </Grid>
                {schedule.maxUsers>1 && <Grid item xs={5} className={'font-weight-bold'}>{intl.formatMessage({id: "forms.book.withFriend"})} </Grid>}
                {schedule.maxUsers>1 && <Grid item xs={7} mt={0}>
                        <RadioGroup row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className={'weekly-confirm-radio-group'}
                            defaultValue={false}
                        >
                            <FormControlLabel value={true} control={<Radio id={'withFriend'}/>} label={intl.formatMessage({id: "common.yes"})}/>
                            <FormControlLabel value={false} control={<Radio/>} label={intl.formatMessage({id: "common.no"})}/>
                        </RadioGroup>
                </Grid>}

                <Grid item xs={12} className={'font-weight-bold'}>
                    <FormattedMessage id={'forms.booking.leave.comment'} defaultMessage={'Leave a comment for the coach'}/>:
                </Grid>
                <Grid item xs={11} mt={0} pt={0}>
                    <Input
                        placeholder={intl.formatMessage({id: 'forms.booking.comment.placeholder', defaultMessage: 'You may leave questions or any additional information here. Coach will contact you as soon as possible...'})}
                        type="textarea"
                        rows="4"
                        id={'clientComment'}
                    />
                </Grid>
            </Grid>
        </ComplexProjectCard>)
        setOpen(true);
    }, [user, coach, locale, intl]);

    const navigate = useNavigate();
    const handleBookSlotComplete = useCallback(async (event) => {
        const {schedule} = event.extendedProps;
        const sessionData = {
            firstName: user.name,
            lastName: user.surname,
            phone: user.phone,
            email: user.email,
            firstTime: Boolean(document.getElementById('firstTime').checked),
            withFriend: schedule.maxUsers<2?false:Boolean(document.getElementById('withFriend').checked),
            clientComment: document.getElementById('clientComment').value
        }
        const booking = {
            ...sessionData,
            coachId: coach.uuid,
            scheduleId: schedule.uuid,
            scheduleData: schedule,
            activity: schedule.activity,
            location: schedule.location,
            startDate: event.start,
            timeStart: schedule.startTime,
            timeEnd: schedule.endTime,
            comment: schedule.comment,
            price: schedule.price
        }
        await api.post('bookings/v2', booking)

        MySwal.fire({
            title: intl.formatMessage({id: "forms.book.booked"}),
            html: locale==='lt'?(
                <div className={'text-bigger'}>
                    <p>
                        Jūsų <strong>{schedule.activity}</strong> sesija su <strong>{coach.fullname}</strong>
                    </p>
                    <p>
                        <strong>{moment(event.start).format('YYYY-MM-DD,  \xa0HH:mm')} - {schedule.endTime}</strong>
                    </p>
                    <p>
                        sėkmingai įvykdyta.
                    </p>
                    <p>
                        Laukite trenerio patvirtinimo “mano treniruotės” sekcijoje.
                    </p>
                </div>
            ):(
                <div className={'text-bigger'}>
                    <p>
                        Your <strong>{schedule.activity}</strong> session with <strong>{coach.fullname}</strong>
                    </p>
                    <p>
                        at <strong>{moment(event.start).format('YYYY-MM-DD,  \xa0HH:mm')} - {schedule.endTime}</strong>
                    </p>
                    <p>
                        has been booked
                    </p>
                    <p>
                        You can now wait for the coach approval in “my trainings” section
                    </p>
                </div>
            ),
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#3085d6",
            denyButtonColor: "#3085d6",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: "forms.book.confirmButtonText"}),
            cancelButtonText: intl.formatMessage({id: "forms.book.cancelButtonText"}),
            denyButtonText: intl.formatMessage({id: "forms.book.denyButtonText"})
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(`/client/bookings`)
            } else if (result.isDenied) {
                navigate(`/search`)
            } else if (result.isDismissed) {
                document.location.reload();
            }
        });

    }, [user, coach, locale, intl]);

    const eventClickHandler = useCallback((event) => {
        const {schedule} = event.extendedProps;
        setOpen(true)
        setModalContent(<ComplexProjectCard
            style={{zIndex: 999999999999}}
            image={coach.avatar}
            title={`${intl.formatMessage({id: 'common.book.with'})}`}
            description={schedule.comment}
            dateTime={moment(event.start).format()}
            members={schedule.bookings||[]}
            footer={(
                <MDBox className={'pre-booking-card-footer'}>
                    <MDButton color={'error'} variant={'contained'} style={{marginRight: 16}}
                              onClick={() => setOpen(false)}>{intl.formatMessage({id: 'schedule.cancel'})}</MDButton>
                    <MDButton onClick={() => handleBookSlot(event)} color={'success'}
                              variant={'contained'}>{intl.formatMessage({id: 'bookings.select.slot'})} <ChevronRight className={'text-bigger'}/></MDButton>
                </MDBox>
            )}
        >

            <Grid container spacing={3} m={1}>
                <Grid item xs={4} className={'font-weight-bold'}>{intl.formatMessage({id: "schedule.datetime"})}</Grid>
                <Grid item xs={8}>{moment(event.start).format('YYYY-MM-DD,  \xa0HH:mm')} - {schedule.endTime}</Grid>

                <Grid item xs={4} className={'font-weight-bold'}>{intl.formatMessage({id: "search.sports"})}</Grid>
                <Grid item xs={8}>{schedule.activity}</Grid>

                <Grid item xs={4} className={'font-weight-bold'}>{intl.formatMessage({id: "forms.book.location"})}</Grid>
                <Grid item xs={8}>{_.find(coach.coachData?.locations, {id: schedule.location})?.name}</Grid>

                <Grid item xs={4} className={'font-weight-bold'}>{intl.formatMessage({id: "bookings.price"})}</Grid>
                <Grid item xs={8}>€{schedule.price}</Grid>

                <Grid item xs={12} className={'font-weight-bold'}>{intl.formatMessage({id: "bookings.coach.comment"})}:
                </Grid>
            </Grid>
        </ComplexProjectCard>)

    }, [events, coach, setOpen, locale,intl]);

    const handleBookSession = () => {

    }
    const isProfilePage = document.location.href.indexOf('coach-profile')!==-1;
    const data = useMemo(()=>{
        if(hideFull) {
            return events.filter(e=>e.bookings.length<e.schedule.maxUsers)
        }
        return events
    }, [events, hideFull])

    useEffect(() => {
        if(calendarRef) {
            setTimeout(()=> {
                const alert1 = document.getElementById('alert1');

                if(isProfilePage) {
                    console.log({isProfilePage})
                    alert1.style.position='absolute';
                    alert1.style.top='-10000px';
                    alert1.style.opacity=0;
                    for(const child of alert1.children) {
                        child.style.position='absolute';
                        child.style.top='-10000px';
                        child.style.opacity=0;
                    }
                } else {
                    if (alert1 && !user.isBusiness && alert1.children && alert1.children.length > 0) {
                        const banner = alert1.children[0];
                        banner.style.opacity = 1;
                        banner.style.display = 'flex';
                        const chunkButton = document.querySelector('.fc-toolbar-chunk>.fc-alert1-button');
                        if (chunkButton && !user.isBusiness) {
                            chunkButton.replaceWith(banner);

                            console.log(banner)
                        }
                    } else if (alert1 && alert1.children && alert1.children.length > 0) {
                        alert1.children[0].style.display = 'none';
                    }
                }
            }, 200)
        }
    }, [calendarRef])


    return (
        <>
            <MDDialog
                style={{marginTop: 80}}
                title={intl.formatMessage({id: "weekly.copy", defaultMessage: 'Copy Slot'})}
                open={open}
                noWrapper={true}
                saveButtonText={'Copy'}
                onClose={handleClose}
                onSave={handleBookSession}
                content={modalContent}
            />
            <MDBox id={'alert1'} style={{zIndex: 99999999, height: 0}}>
                <MDAlert id={'alert1'}
                         sx={{
                             zIndex: 1,
                             position: {xs: 'absolute', sm: 'absolute', md: 'relative', lg:'relative', xl:'relative', xxl:'relative', xxxl:'relative'},
                             left: {xs: '4px', sm: '40px', md: 'auto'},
                             width: '100%',
                             maxWidth: '100%',
                             top:{xs: 120, md: 'auto',}
                         }}
                         style={{
                             opacity: 0,
                             //position: 'absolute',
                             padding:4,
                             fontWeight: 400,
                             minWidth: '50%',
                         }}
                         dismissible={true}
                         color={'info'}
                >
                    <div className={'toolbar-alert-icon'}><Info fontSize={'large'}/></div>
                    <MDBox sx={{
                        fontSize: {
                            xs:'0.7rem',
                            lg: '0.75rem',
                            xxxl: '1rem'
                        }
                    }} style={{color:'white'}}><FormattedMessage id={"common.schedules.alert"}/></MDBox>
                </MDAlert></MDBox>
            <div style={{height: '100%', minHeight: 17 * 60}}>

                {coach?.uuid !== user?.uuid && <div className={'hide-full-switch'}>
                    <MDBox>
                        <Switch title={intl.formatMessage({id: 'bookings.only.available'})} color={'info'}
                                checked={hideFull}
                                onChange={() => setHideFull(v => !v)}/> {intl.formatMessage({id: 'bookings.only.available'})}
                    </MDBox>
                </div>}

                <Calendar
                    noCard={coach?.uuid !== user?.uuid}
                    cardHeight={minCardHeight}
                    themeSystem={'bootstrap5'}
                    events={data}
                    passRef={getRef}
                    header={{title: !isCoach ? "Select time slot" : intl.formatMessage({id: "calendar.header.coach"})}}
                    height={'100%'}
                    initialView={'timeGridWeek'}
                    allDaySlot={false}
                    dayMinWidth={200}
                    timeZone={'Europe/Vilnius'}
                    slotDuration={'01:00'}
                    slotMinTime={'06:00'}
                    slotMaxTime={'23:00'}
                    expandRows={true}
                    stickyHeaderDates={true}
                    slotMinWidth={200}
                    buttonText={{
                        next: intl.formatMessage({id: "calendar.nextweek"}),
                        prev: intl.formatMessage({id: "calendar.prevweek"}),
                        listWeek: intl.formatMessage({id: "bookings.list"}),
                        listMonth: intl.formatMessage({id: "bookings.list"}),
                        listYear: intl.formatMessage({id: "bookings.list"}),
                        timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                        today: intl.formatMessage({id: "bookings.thisweek"})
                    }}
                    headerToolbar={{
                        start: 'title',
                        center: isProfilePage?'':'alert1',
                        end: 'today prev next'
                    }}
                    slotLabelFormat={(date) => {
                        return `${date.start.hour.toString().padStart(2, '0')}:00　`
                    }}
                    eventContent={renderEvent}
                    slotLabelContent={initialScreenSize>1200?renderSlotLabel:false}
                    selectable={false}
                >{children}
                </Calendar>
            </div>
        </>
    );
}

export default BookingCalendar;
